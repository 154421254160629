import { defineStore } from 'pinia';
import type { LignePrecommande } from '~/types/models/lignePrecommande';
import type { MetreTravaux } from '~/types/models/metreTravaux';
import type { ArticleLibre, Precommande, TypePrecommande } from '~/types/models/precommande';

import {
  JalonPnl,
  MetreTravauxStatus,
  PrecommandeStatus,
  PrecommandeTypes,
  TablesNames,
} from '~/types/Enums';
import { useFiltersStore } from './filters';
import { useDernierPrixConnuHt } from '~/composables/contrats/useDernierPrixConnuHt';
import { DateTime } from 'luxon';
import type { TableFiltersValues } from '~/types/TableTypes';

type State = {
  metres: MetreTravaux[];
  page: number;
  pageCount: number;
  sortField: string;
  sortDirection: string;
  query: string;
  currentMetre: MetreTravaux | null;
  currentPrecommande: Precommande | Creatable<Precommande> | null;
  filters: TableFiltersValues;
};

export const useMetresTravauxStore = defineStore('metresTravaux', {
  state: (): State => ({
    metres: [],
    page: 1,
    pageCount: 1,
    sortField: 'createdAt',
    sortDirection: ':desc',
    query: '',
    currentMetre: null,
    currentPrecommande: null,
    filters: {},
  }),
  getters: {
    allLignes(): LignePrecommande[] {
      return (
        this.currentMetre?.precommandes.reduce(
          (acc: LignePrecommande[], precommande: Precommande) => {
            return [...acc, ...(precommande.lignes || [])];
          },
          [],
        ) || []
      );
    },
    metreClient() {
      return (metre: MetreTravaux): any => {
        const lastAvp = metre.projet.avps[0];
        return lastAvp.metre;
      };
    },
    queryFilters: (state) => useFiltersStore().queryFilters(TablesNames.METRES_TRAVAUX),
    querySorts: () => useDatasTablesState(TablesNames.METRES_TRAVAUX).querySorts(),
    margeMetreTravaux() {
      return (metre: MetreTravaux): number => {
        const dpc = useDernierPrixConnuHt(metre.projet.contrat);
        const deboursTotal = this.deboursAjusteTotal(metre);
        // <Marge métré travaux>=((<Dernier_prix_connu_TTC>/(1+taux de TVA))-<Debours_total_métré_travaux>)/(<Dernier_prix_connu_TTC>/(1+taux de TVA)
        return 1 - deboursTotal / dpc;
      };
    },
    deboursAjusteTotal() {
      return (metre: MetreTravaux): number => {
        return (
          this.precommandesValideesForMetreTravaux(metre).reduce((acc, precommande) => {
            return acc + this.deboursAjustePrecommande(precommande);
          }, 0) || 0
        );
      };
    },

    deboursMetreLigne() {
      return (lp: LignePrecommande): number => {
        if (!lp.devis) {
          const prix =
            lp.metre_ligne?.prix_unitaire ?? lp.metre_ligne?.article_prix ?? lp.article?.prix ?? 0;
          const articleCode = lp.metre_ligne?.article?.code || lp.article?.code;
          const ml = this.allLignes.find(
            (l) => l.metre_ligne?.article?.code === articleCode,
          )?.metre_ligne;
          const quantite = ml?.quantite ?? 0;
          return prix * quantite;
        } else {
          const precommande = this.precommandeFromLigne(lp);
          return (
            precommande?.lignes
              ?.filter((l) => !!!l.devis)
              .reduce((acc, ligne) => {
                return acc + this.deboursMetreLigne(ligne);
              }, 0) || 0
          );
        }
      };
    },

    deboursMetrePrecommande() {
      return (precommande: Precommande): number =>
        precommande.lignes
          ?.filter(
            (l) => !!l.devis || !precommande.lignes?.some((ll: LignePrecommande) => !!ll.devis),
          )
          .reduce((acc, ligne) => {
            return acc + this.deboursMetreLigne(ligne);
          }, 0) || 0;
    },

    deboursMetreParLot() {
      return (lotId: number, type?: PrecommandeTypes): number => {
        const precommandes = this.precommandesValidees(type).filter((precommande) => {
          const lot = precommande.lot.parent || precommande.lot;
          return lot.id === lotId;
        });
        return precommandes.reduce((acc, precommande) => {
          return acc + this.deboursMetrePrecommande(precommande);
        }, 0);
      };
    },

    deboursAjusteLigne() {
      return (lp: LignePrecommande): number => {
        const prix = lp.prixAjuste;
        const quantite = lp.quantiteAjustee;
        return prix * quantite;
      };
    },

    precommandeHasDevis() {
      return (precommande: Precommande): boolean => {
        return !!precommande.lignes?.some((l) => !!l.devis);
      };
    },

    deboursAjustePrecommande() {
      return (precommande: Precommande): number => {
        const lignes = !this.precommandeHasDevis(precommande)
          ? precommande.lignes
          : precommande.lignes?.filter((l) => {
              return !!l.devis;
            });

        return (
          lignes?.reduce((acc, ligne) => {
            return acc + this.deboursAjusteLigne(ligne);
          }, 0) || 0
        );
      };
    },

    deboursAjusteParLot() {
      return (lotId: number, type?: PrecommandeTypes): number => {
        const precommandes = this.precommandesValidees(type).filter((precommande) => {
          const lot = precommande.lot.parent || precommande.lot;
          return lot.id === lotId;
        });
        return precommandes.reduce((acc, precommande) => {
          return acc + this.deboursAjustePrecommande(precommande);
        }, 0);
      };
    },

    precommandeFromLigne() {
      return (ligne: LignePrecommande): Precommande | undefined => {
        return this.currentMetre?.precommandes.find((p) => {
          const id =
            typeof ligne.precommande === 'number' ? ligne.precommande : ligne.precommande?.id;
          return p.id === id;
        });
      };
    },

    precommandes(): Precommande[] {
      return this.currentMetre?.precommandes || [];
    },

    precommandesValidees() {
      return (type?: PrecommandeTypes): Precommande[] =>
        this.currentMetre ? this.precommandesValideesForMetreTravaux(this.currentMetre, type) : [];
    },

    precommandesValideesForMetreTravaux() {
      return (metre: MetreTravaux, type?: PrecommandeTypes): Precommande[] => {
        return (
          metre.precommandes.filter((p) => {
            return (
              [PrecommandeStatus.VALIDE, PrecommandeStatus.TERMINE].includes(p.statut) &&
              (!type || p.type === type)
            );
          }) || []
        );
      };
    },

    precommandesWithSortedLignes() {
      return (type?: PrecommandeTypes): Precommande[] => {
        this.precommandes.forEach((precommande) => {
          precommande.lignes?.sort((a, b) => {
            return a.ordre - b.ordre;
          });
        });
        return type ? this.precommandes.filter((p) => p.type === type) : this.precommandes;
      };
    },
    precommandeToXlsJsonData() {
      return (precommande: Precommande): { [key: string]: string }[] => {
        const data: any[] = [];
        precommande.lignes?.forEach((ligne) => {
          const row = {
            Description:
              ligne.description ||
              ligne.metre_ligne?.description_notice ||
              ligne.metre_ligne?.article_description_travaux ||
              ligne.metre_ligne?.article_description_notice ||
              ligne.article?.description_travaux ||
              ligne.article?.description_notice ||
              ligne.articleLibre?.description ||
              useConstants.NA,
            Gamme:
              ligne.metre_ligne?.article?.gamme?.libelle ||
              ligne.article?.gamme?.libelle ||
              useConstants.NA,
            'Infos Complémentaires': ligne.descriptionComplementaire || '',
            U: ligne.metre_ligne?.article?.unite || ligne.article?.unite || useConstants.NA,
            Quantité: ligne.quantiteAjustee,
            'Prix unitaire': ligne.prixAjuste,
          };
          data.push(row);
        });
        return data;
      };
    },
    marches(): Precommande[] {
      return this.precommandesWithSortedLignes(PrecommandeTypes.MT);
    },
    commandes(): Precommande[] {
      return this.precommandesWithSortedLignes(PrecommandeTypes.CMD);
    },
    allPrecommandes(): Precommande[] {
      return this.precommandesWithSortedLignes();
    },
    metreIsEditable() {
      return (metre: MetreTravaux): boolean => {
        return metre.statut !== MetreTravauxStatus.VALIDE;
      };
    },
    precommandeIsEditable() {
      return (precommande?: Precommande): boolean => {
        if (!precommande) return false;
        return !precommande.commandeGeneree && this.metreIsEditable(this.currentMetre!);
      };
    },
    ligneIsEditable() {
      return (ligne: LignePrecommande): boolean => {
        const precmd = this.precommandeFromLigne(ligne);
        return this.precommandeIsEditable(precmd!) && !!ligne.actif;
      };
    },
    precommadesForGenerationCommandes() {
      return (metre: MetreTravaux, type?: PrecommandeTypes): Precommande[] => {
        return (
          metre.precommandes.filter((p) => {
            return (
              (!type || p.type === type) &&
              !p.commandeGeneree &&
              p.statut === PrecommandeStatus.VALIDE
            );
          }) || []
        );
      };
    },

    canCreateCommandes() {
      return (metre: MetreTravaux, type?: PrecommandeTypes): boolean => {
        const { $ability } = useNuxtApp();
        const precmds = this.precommadesForGenerationCommandes(metre, type);
        return (
          $ability.can('validate', 'workspace.travaux.mt') &&
          precmds.length > 0 &&
          this.metreIsEditable(metre)
        );
      };
    },
    filterPrecommandesLignesBySousLot() {
      return (precommande: Precommande, sousLot?: any | null): LignePrecommande[] => {
        const lignesSousLot = sousLot
          ? precommande?.lignes?.filter(
              (l) =>
                (l.metre_ligne?.article?.sous_lot || l.article?.sous_lot)?.code === sousLot?.code,
            )
          : precommande?.lignes?.filter(
              (l) => !l.metre_ligne?.article?.sous_lot && !l.article?.sous_lot,
            );
        return lignesSousLot || [];
      };
    },
  },
  actions: {
    async fetch() {
      this.filters = await useApi().metresTravaux.getFilters();
      const res = await useApi().metresTravaux.find(
        this.page,
        this.querySorts,
        this.query,
        25,
        { ...this.queryFilters },
        { projet: true },
      );
      if (res && res.data) {
        this.metres = res.data;
        this.page = res.meta?.pagination.page || 1;
        this.pageCount = res.meta?.pagination.pageCount || 1;
      }
    },

    async fetchOne(id: number) {
      const res = await useApi().metresTravaux.findOne(id);
      if (res && res.data) {
        this.currentMetre = res.data;
      }
      return res;
    },

    async update(metre: MetreTravaux) {
      const res = await useApi().metresTravaux.update(metre);
      if (res && res.data) {
        const index = this.metres.findIndex((item) => item.id === metre.id);
        this.metres[index] = res.data;
        return res.data;
      }
      return null;
    },

    setMetaSortPage({ page = 1, direction = '', sortField = '' }) {
      this.page = page;
      if (direction) this.sortDirection = direction;
      if (sortField) this.sortField = sortField;
    },

    setQuery(query: string) {
      this.page = 1;
      this.query = query;
    },

    setCurrentMetre(metre: MetreTravaux | null) {
      this.currentMetre = metre;
    },

    async refresh() {
      await this.fetch();
    },

    async refreshOne(id: number) {
      await this.fetchOne(id);
    },

    reset() {
      this.metres = [];
      this.currentMetre = null;
      this.query = '';
    },

    async createPrecommande(lotId: number, type: TypePrecommande, nom: string) {
      const precommande = await useApi().precommandes.create({
        nom,
        type,
        lot: lotId,
        metre_travaux: this.currentMetre?.id,
        statut: PrecommandeStatus.EN_COURS,
        lignes: [],
        commandeGeneree: false,
      });

      if (precommande?.data) {
        this.currentMetre?.precommandes.push(precommande.data);
      }
    },

    setCurrentPrecommande(precommande: Creatable<Precommande> | null) {
      this.currentPrecommande = precommande;
    },

    async updateOrCreatePrecommande(precommande: Precommande) {
      if (precommande.id) {
        await useApi().precommandes.update(precommande);
      } else {
        const lignes = precommande.lignes || [];
        precommande.lignes = [];
        const createReq = await useApi().precommandes.create(precommande);
        const newP = createReq?.data;

        if (!newP) return;

        const lignesIds = [];
        for (let i = 0; i < lignes.length; i++) {
          const l = lignes[i];
          l.precommande = newP.id;
          let newLigne = await useApi().lignesPrecommande.create(l);
          if (newLigne?.data) lignesIds.push(newLigne.data.id);
        }
      }
      if (this.currentMetre?.id) await this.refreshOne(this.currentMetre.id);
    },

    async updateLignePrecommande(ligne: LignePrecommande) {
      const res = await useApi().lignesPrecommande.update(ligne);
      return res?.data || null;
    },

    async removeLignePrecommande(ligne: LignePrecommande) {
      const precommande = this.precommandeFromLigne(ligne);
      if (precommande) {
        await useApi().lignesPrecommande.delete(ligne);
        precommande.lignes = precommande.lignes?.filter((l) => l.id !== ligne.id) || [];
      }
    },

    async deletePrecommande(precommande: Precommande) {
      for (let i = 0; i < (precommande.lignes?.length || 0); i++) {
        await this.removeLignePrecommande(precommande.lignes![i]);
      }

      await useApi().precommandes.delete(precommande);
      if (this.currentMetre)
        this.currentMetre.precommandes = this.currentMetre.precommandes.filter(
          (p) => p.id !== precommande.id,
        );
    },

    newLigneArticleLibre(article: ArticleLibre) {
      article.id = crypto.randomUUID();

      const newLigne: Creatable<LignePrecommande> = {
        description: '',
        articleLibre: article,
        descriptionComplementaire: '',
        prixAjuste: article.debours,
        quantiteAjustee: 1,
        ordre: 0,
      };

      return newLigne;
    },

    async createLignePrecommande(ligne: Creatable<LignePrecommande>) {
      const newligne = await useApi().lignesPrecommande.create(ligne);
      if (newligne?.data) {
        return newligne.data;
      }
      return null;
    },

    async findArticles(q: string) {
      const res = await useApi().articles.find(1, 'code', q, 10, {
        $or: [{ temp: { $null: true } }, { temp: false }],
      });
      return res;
    },

    async addArticleFromCatalogueToPrecommande(
      article: any,
      precommande: Precommande,
      create: boolean = true,
    ) {
      if (!article || !precommande) return;
      const quantite = this.currentMetre
        ? await useApi().metres.articleQuantite(
            this.metreClient(this.currentMetre).id,
            article.code,
          )
        : 0;

      const lignesSousLot = this.filterPrecommandesLignesBySousLot(precommande, article.sous_lot);

      const maxOrdreSousLot = lignesSousLot.reduce((acc, l) => {
        return l.ordre > acc ? l.ordre : acc;
      }, 0);

      const ordre = maxOrdreSousLot + 1;

      const ligne = {
        article: article,
        precommande: precommande,
        prixAjuste: article.prix,
        quantiteAjustee: 1,
        quantiteMetre: quantite,
        descriptionComplementaire: '',
        ordre: ordre,
      } as LignePrecommande;
      if (create) {
        const newLigne = await this.createLignePrecommande(ligne);
        if (newLigne) {
          precommande.lignes?.push(newLigne);
        }
      } else {
        precommande.lignes?.push(ligne);
      }
    },

    async updatePrecommande(precommande: Precommande) {
      const res = await useApi().precommandes.update(precommande);
      return res?.data || null;
    },

    async validateMetreTravaux(metre: MetreTravaux) {
      if (!metre.id) return;
      const res = await useApi().metresTravaux.validate(metre.id);
      return res;
    },

    async generateCommandes(metre: MetreTravaux, type?: PrecommandeTypes) {
      if (!metre.id) return;
      const res = await useApi().metresTravaux.validate(metre.id, type);

      const updatedMtReq = await useApi().metresTravaux.findOne(metre.id, {});
      if (updatedMtReq?.data) {
        if (updatedMtReq.data.statut === MetreTravauxStatus.VALIDE) {
          const projetReq = await useApi().projets.findOne(metre.projet.id, { contrat: true });
          if (projetReq?.data) {
            const contrat = projetReq.data.contrat;
            if (contrat) {
              await useApi().contrats.savePnl(
                contrat.id,
                JalonPnl.GENERATION_BDC,
                DateTime.now().toISO(),
              );
            }
          }
        }
      }

      return res;
    },

    async mergePrecommandes(destination: Precommande, toDelete: Precommande) {
      const destinationPrecmd = this.currentMetre?.precommandes.find(
        (p) => p.id === destination.id,
      );
      const toDeletePrecmd = this.currentMetre?.precommandes.find((p) => p.id === toDelete.id);

      if (!destinationPrecmd || !toDeletePrecmd) return;

      if (toDeletePrecmd.lignes)
        for (let i = 0; i < toDeletePrecmd.lignes?.length; i += 1) {
          const ligne = toDeletePrecmd.lignes[i];
          destinationPrecmd.lignes?.push(ligne);
          const updatedLigne = await this.updateLignePrecommande({
            id: ligne.id,
            precommande: destinationPrecmd.id,
          } as LignePrecommande);
          if (updatedLigne) {
            ligne.precommande = updatedLigne.precommande;
          }
        }

      const updated = await this.updatePrecommande(destinationPrecmd);
      if (updated) {
        toDeletePrecmd.lignes = [];
        await this.deletePrecommande(toDeletePrecmd);
      }
    },

    async duplicatePrecommande(precommande: Precommande) {
      const newPrecommande = await useApi().precommandes.duplicate(precommande);
      if (newPrecommande && this.currentMetre) {
        this.currentMetre.precommandes.push(newPrecommande);
      }
    },

    canMoveLigne(ligne: LignePrecommande, direction: 'up' | 'down') {
      const sousLot = ligne.metre_ligne?.article?.sous_lot || ligne.article?.sous_lot;
      const precommande = this.precommandeFromLigne(ligne);
      if (!precommande) return false;
      const lignesSousLot = this.filterPrecommandesLignesBySousLot(precommande, sousLot);
      const indexLigneActuelle = lignesSousLot.findIndex((l) => l.id === ligne.id);

      if (direction === 'down' && indexLigneActuelle === lignesSousLot.length - 1) return false;
      if (direction === 'up' && indexLigneActuelle === 0) return false;

      return true;
    },

    moveLigne(ligne: LignePrecommande, direction: 'up' | 'down') {
      const sousLot = ligne.metre_ligne?.article?.sous_lot || ligne.article?.sous_lot;
      const precommande = this.precommandeFromLigne(ligne);
      if (!precommande) return;
      const lignesSousLot = this.filterPrecommandesLignesBySousLot(precommande, sousLot);
      const indexLigneActuelle = lignesSousLot.findIndex((l) => l.id === ligne.id);

      if (direction === 'down' && indexLigneActuelle === lignesSousLot.length - 1) return;
      if (direction === 'up' && indexLigneActuelle === 0) return;

      const indexOffset = direction === 'up' ? -1 : 1;
      const ligneSuivante = lignesSousLot[indexLigneActuelle + indexOffset];
      const ordreLigneActuelle = ligne.ordre;
      const ordreLigneSuivante = ligneSuivante.ordre;

      ligneSuivante.ordre = ordreLigneActuelle;
      ligne.ordre = ordreLigneSuivante;

      if (ligneSuivante.ordre === ligne.ordre) {
        ligneSuivante.ordre += 1;
      }

      this.updateLignePrecommande(ligne);
      this.updateLignePrecommande(ligneSuivante);
    },
    async moveLigneToPrecommande(ligne: LignePrecommande, precommande: Precommande) {
      const precommandeDest = { ...precommande };
      delete precommandeDest.lignes;

      const oldPrecommande = this.precommandeFromLigne(ligne);
      if (!oldPrecommande) return;

      oldPrecommande.lignes = oldPrecommande.lignes?.filter((l) => l.id !== ligne.id) || [];

      ligne.precommande = precommandeDest;

      if (!precommande.lignes) precommande.lignes = [];
      precommande.lignes.push(ligne);

      await useApi().lignesPrecommande.update({ id: ligne.id, precommande: precommandeDest.id });
    },
  },
});
